.content-container {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    row-gap: 2rem;
    justify-content: space-around;
    align-items: flex-end;
    flex-wrap: wrap;
}

.content-wrap {
    color: rgb(20, 1, 62);
    position: relative;
    padding-bottom: 2rem;
    background-color: rgb(176, 205, 248);
    z-index: -1;
}


.img-container {
    width: 30rem;
    height: 35rem;
    overflow: hidden;
    border-radius: 15rem 15rem 0 0;
    border: 8px solid rgb(131, 178, 248);
} 

.img-container>img {
    width: 100%;
    height: 100%;
}

.content-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.content-left {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 3rem;
}

.content-title {
    position: relative;
    z-index: 1;
}
.content-title h1 {
    font-weight: 600;
    font-size: 3.8rem ;
    line-height: 4rem;
}

.content-right {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.orange-circle {
    height: 4rem;
    width: 4rem;
    background: linear-gradient(rgb(133, 213, 248),rgb(106, 200, 250),rgb(77, 172, 223));
    border-radius: 100% 0% 70% 30% / 45% 13% 87% 55%  ;
    position: absolute;
    right: 40%;
    top: -12%;
    z-index: -1;
}

.search-bar {
    background-color: white;
    border-radius: 15px;
    border: 3px solid whitesmoke;
    padding: 0.5rem 1rem;
    justify-content: space-between ;
    display: flex;
    align-items: center;
    width: 100%;
}

.search-bar input {
    border: none;
    outline: none;
}

.status {
    display: flex;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
}

.status2>:nth-child(1)>:last-child {
    color: rgb(58, 115, 248);
}

.status2 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.secondary-text {
    color: rgb(44, 3, 132);
    font-size: 0.9rem;
}

@media (max-width:600px) {
    .content-container{
        /* margin-top: 2rem; */
        background:rgb(176, 205, 248);
    }
    .content-title>h1 {
        font-size: 2.5rem;
        line-height: 3rem;
    }
    .img-container {
        width: 95%;
        height: 25rem;
    }
    .status {
        justify-content: center;
        gap: 1.5rem;
    }
    .content-right{
        width: 100%;
    }
    .status2:nth-child(1)
    {
        font-size: 1.5rem;
    }
    .status2:nth-child(2)
    {
        font-size: .8rem;
    }
}

