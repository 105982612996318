.wrap {
    background-color: rgb(20, 1, 62);
    color: white;
    width: 100%;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    row-gap: 2rem;
    flex-wrap: wrap;
    padding: 1.5rem;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    color: white;
}

.header-container>h1 {
    margin-right: auto;
    color: #cba445;
}

.header-menu {
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: white;
}

.header-menu:hover{
    cursor: pointer;
}
 
a {
    color: white;
    text-decoration: none;
}
.menu-icon {
    display: none;
}
@media (max-width : 768px) {
    .menu-icon {
        display: block;
    }
    .header-menu {
        color: black;
        position: absolute;
        top: 3rem;
        right: 4rem;
        background: white;
        flex-direction: column;
        font-weight: 500;
        gap: 2rem;
        padding: 3rem;
        border-radius: 10px;
        align-items: center;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
        transition: all 300ms ease-in;
    }
    .header-menu a{
        color: black;
    }
}
