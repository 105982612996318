* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button {
  font-weight: 500;
  padding: 0.6rem 1.4rem;
  color: white;
  background: linear-gradient(rgb(62, 62, 248),rgb(93, 19, 240));
  border-radius: 4px;
  border: none;
  transition: all 100ms ease-in ;
}

button:hover {
  cursor: pointer;
  transform: scale(1.1);
}

.primaryText {
  color: black;
  font-weight: bold;
  font-size: 2rem;
}

.secondaryText {
  color: black;
  font-size: 0.9rem;
}
.blueText {
  color: blue;
  font-size: 1.5rem;
  font-weight: 600;
}