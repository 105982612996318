.contact-container {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    row-gap: 2rem;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.contact-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;  
    gap: 0.5rem;
}
.contact-container>div {
    flex: 1;
}

.contact-right {
    width: 100%;
}

.contactModes {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin-top: 2rem;
    gap: 1rem;
}

.contactModes .row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1.5rem;
}

.mode {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 16rem;
    padding: 1rem;
    border: 0.8px solid rgba(128, 128, 128, 0.143);
    border-radius: 5px;
    gap: 1rem;
    transition: all 300ms ease-in;
    transition-property: transform, box-shadow;
}

.mode .button {
    width: 100%;
    font-size: 0.9rem;
    font-weight: 600; 
}

.mode .button:hover {
    background: blue;
    color: white;
    scale: 0.8 ;
}

.mode:hover {
    scale: 1.1;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }

  .mode>:nth-child(1)
  {
    width: 100%;
    gap: 1.6rem;
  }

  .mode .detail .primaryText {
    font-size: 1.1rem;
    font-weight: 600;
  }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.flexStart {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

.button {
    display: flex;
    row-gap: 2rem;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

@media (max-width:1024px) {
    .contact-container {
        flex-direction: column;
    }
    .contact-right {
        justify-content: center;
    }
    .row {
        flex-direction: column;
        width: 100%;
    }
    .contactModes {
        width: 100%;
    }
    .mode {
        width: 100%;
    }
}
