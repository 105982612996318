.comp-wrap {

}

.comp-container {
    padding: 1.5rem;
    width: 100%;
    display: flex;
    row-gap: 2rem;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}

.comp-container img {
    width: 9rem;
}